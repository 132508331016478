<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-dashboard' }">
    <template #title>
      <span>{{ translateTitleCase("admin.media.dashboardTitle") }}</span>
    </template>

    <template #default>
      <div>
        <media-manager
          :media-select="false"
          @media:item-selected="testMethod"></media-manager>
      </div>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";

export default {
  components: {},
  layout: "adminDashboard",
  middleware: {
    requireSuperAdmin: {},
  },
  props: {},
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "admin.media.dashboardBrowserTitle",
  data: function () {
    return {
      asyncData: {},
    };
  },
  computed: {},
  methods: {
    testMethod(val) {
      console.log("slected", val);
    },
  },
};
</script>

<style scoped lang="scss"></style>
